import './crew.scss';

const Crew = () => {
  return (
    <div className="crew">
      <div className="crewCard">
        <div className="imgWrapper">
          <img className='resize' src="/assets/crew/lucille.webp" alt="Photo de Lucille, la chanteuse du groupe" style={{ objectPosition: 'top 10% !important' }} /> 
        </div>
        <div className="left">
          <div className="text">
            <span>Lucille</span>
            <span>Chanteuse</span>
            <p>Passionnée de musique depuis toute petite, Lucille a toujours chanté en plus d’avoir appris à jouer du violon et du piano. Elle met aujourd’hui sa sensibilité et ses influences issues de ses différents voyages au service des chansons afin de vous faire passer un agréable moment.</p>
          </div>
        </div>
      </div> 
      <div className="crewCard reverse">
        <div className="imgWrapper">
          <img src="/assets/crew/belga_color.webp" alt="Photo de Nasser, le percussioniste du groupe " /> 
        </div>
        <div className="left">
          <div className="text">
            <span>Belga</span>
            <span>Guitariste et choriste</span>
            <p>Créateur du groupe, Belga dispose d’une très grande expérience musicale et scénique. Ancien bassiste des groupes Les Alchimistes et Alwane, il est aujourd’hui guitariste et choriste pour Eklektik. Il crée des structures de morceaux entrainantes et inédites, et utilise son aisance et son énergie afin d’interagir avec le public : ambiance assurée !</p>
          </div>
        </div>
      </div> 
      <div className="crewCard">
        <div className="imgWrapper">
          <img src="/assets/crew/nasser_color.webp" alt="Photo de Nasser, le percussioniste du groupe " /> 
        </div>
        <div className="left">
          <div className="text">
            <span>Nasser</span>
            <span>Percussionniste au cajón et multipads</span>
            <p>Ancien batteur des groupes Five To One (blues) et Alwane (Raï), Nasser cumule de nombreuses années d’expérience scénique qu’il met désormais au service de la percussion d’Eklekitk. Doté de son cajón et de ses multipads, il s’adapte à tous les styles et saura vous faire bouger !</p>
          </div>
        </div>
      </div> 
      <div className="crewCard reverse">
        <div className="imgWrapper">
          <img src="/assets/crew/reyles_color.webp" alt="Photo de Reyles, le guitariste du groupe " /> 
        </div>
        <div className="left">
          <div className="text">
            <span>Reyles</span>
            <span>Guitariste solo</span>
            <p>Guitariste solo du groupe, Reyles est issue d’une famille de musiciens qui lui a donné goût à la musique dès son plus jeune âge. Autodidacte, il a tout appris par lui-même et ne compte pas s’arrêter là. Habitué de la scène, il se démarque par ses solos et ses gimmicks qui vous resteront en tête.</p>
          </div>
        </div>
      </div> 
    </div>
  );
}

export default Crew;