import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Prestation from './pages/prestation/prestation';
import PrestationForm from './pages/prestationForm/prestationForm';
import Band from './pages/band/band';
import "./styles/reset.css"
import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Band />,
  },
  {
    path: "/prestation",
    element: <Prestation />,
  },
  {
    path: "/form",
    element: <PrestationForm />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
