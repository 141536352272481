import Form from '../../components/form/form';
import Header from '../../components/header/header';
import './prestationForm.scss';

const PrestationForm = () => {
  return (
    <div className="App">
        <Header />
        <Form />
    </div>
  );
}

export default PrestationForm;
