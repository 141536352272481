import { Link } from 'react-router-dom';
import './hero.scss';

const Hero = () => {
  return (
    <div className="hero">
      <div className="wrapper">
        <img src="/assets/crew/band.webp" alt="Eklektik band" />
        <div className="titleCard">
            <img src="/assets/logo.webp" alt="Eklektik logo" />
            <p>4 musiciens issus d’horizons divers ont décidé de former un groupe électroacoustique et de partager ensemble leur expérience pour créer une musique <strong>éclectique</strong>.</p>
            <p>Au programme, chansons <strong>espagnoles</strong>, <strong>françaises</strong> et <strong>anglaises</strong> pour une ambiance assurée !</p>
            <Link to={`/form`} className='button' >Demander une prestation</Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;