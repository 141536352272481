import './schema.scss';

const Schema = () => {
  return (
    <div className="schema">
        <img className='desktop' src="/assets/prestation/schema.webp" alt="Eklektik technical schema" />
        <img className='mobile' src="/assets/prestation/schemaMobile.webp" alt="Eklektik technical schema" />
    </div>
  );
}

export default Schema;