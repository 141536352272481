import Header from '../../components/header/header';
import Event from '../../components/event/event';
import Schema from '../../components/schema/schema';
import './prestation.scss';

const Prestation = () => {
  return (
    <div className="App">
        <Header />
        <Event />
        <Schema />
    </div>
  );
}

export default Prestation;
