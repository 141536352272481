import React, { useState } from 'react';
import { Link } from "react-router-dom";
import  { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import './header.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <header className="header">
      <Link to={`/`}><img src="/assets/logo.webp" alt="Eklektik logo" /></Link>
      <div className="menu" data-is-open={isMenuOpen} >
        <div className="options" data-is-open={isMenuOpen} >
          <Link to={`/`} onClick={toggleMenu}>Groupe</Link>
          <Link to={`/prestation`} onClick={toggleMenu}>Prestations</Link>
          <Link to={"https://linktr.ee/eklektik_groupe"} target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Nous suivre</Link>
        </div>
        <Link to={`/form`} className='button' >Demander une prestation</Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <IconButton aria-label='Hamburger menu icon' icon={ isMenuOpen ? <CloseIcon boxSize={16} /> : <HamburgerIcon />} onClick={toggleMenu} className={isMenuOpen ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
    </header>
  );
}

export default Header;