import React from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "../../../node_modules/yet-another-react-lightbox/dist/styles.css";
import './gallery.scss';

const photos = [
  {
    src: "/assets/gallery/15.webp",
    width: 533.33,
    height: 800
  },
  {
    src: "/assets/gallery/16.webp",
    width: 1200,
    height: 800
  },
  {
    src: "/assets/gallery/17.webp",
    width: 533.33,
    height: 800
  },
  {
    src: "/assets/gallery/18.webp",
    width: 1200,
    height: 800
  },
  {
    src: "/assets/gallery/19.webp",
    width: 1204.71,
    height: 800
  },
  {
    src: "/assets/gallery/20.webp",
    width: 1677,
    height: 800
  },
  {
    src: "/assets/gallery/1.webp",
    width: 1430,
    height: 800
  },
  {
    src: "/assets/gallery/2.webp",
    width: 1280,
    height: 800
  },
  {
    src: "/assets/gallery/3.webp",
    width: 1280,
    height: 800
  },
  {
    src: "/assets/gallery/4.webp",
    width: 1430,
    height: 800
  },
  {
    src: "/assets/gallery/5.webp",
    width: 1280,
    height: 800
  },
  {
    src: "/assets/gallery/6.webp",
    width: 1280,
    height: 800
  },
  {
    src: "/assets/gallery/7.webp",
    width: 1430,
    height: 800
  },
  {
    src: "/assets/gallery/8.webp",
    width: 1288.89,
    height: 800
  },
  {
    src: "/assets/gallery/9.webp",
    width: 1440,
    height: 800
  },
  {
    src: "/assets/gallery/10.webp",
    width: 1288.89,
    height: 800
  },
  {
    src: "/assets/gallery/11.webp",
    width: 1288.89,
    height: 800
  },
  {
    src: "/assets/gallery/12.webp",
    width: 1422.22,
    height: 800
  },
  {
    src: "/assets/gallery/13.webp",
    width: 1280,
    height: 800
  },
  {
    src: "/assets/gallery/14.webp",
    width: 1412.35,
    height: 800
  },
]
const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const slides = photos.map((photo) => {
    const width = photo.width * 4;
    const height = photo.height * 4;
    return {
      src: photo.src,
      width,
      height,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: photo.src,
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    };
  });

const GalleryComponent = () => {
    const [index, setIndex] = React.useState(-1);

    return (
      <div className="gallery">
        <PhotoAlbum
            layout="rows"
            photos={slides}
            targetRowHeight={150}
            onClick={({ index: current }) => setIndex(current)}
        />

        <Lightbox
            index={index}
            slides={slides}
            open={index >= 0}
            close={() => setIndex(-1)}
        />
      </div>
    );
}

export default GalleryComponent;
