import GalleryComponent from '../../components/gallery/gallery';
import Crew from '../../components/crew/crew';
import Header from '../../components/header/header';
import Hero from '../../components/hero/hero';
import './band.scss';

const Band = () => {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Crew /> 
      <GalleryComponent />
    </div>
  );
}

export default Band;
