import './event.scss';

const Event = () => {
  return (
    <div className="event">
      <div className="imgWrapper" />
      <div className="textWrapper">
        <span className='title'>Vous recherchez un groupe pour des événements festifs en salle ou en plein air ?</span>
        <div className="tagWrapper">
            <div className="line">
                <div className="tag">
                    <span>Bars d’ambiance</span>
                </div>
                <div className="tag">
                    <span>Café-concert</span>
                </div>
                <div className="tag">
                    <span>Restaurants</span>
                </div>
            </div>
            <div className="line">
                <div className="tag">
                    <span>Anniversaires</span>
                </div>
                <div className="tag">
                    <span>Mariages</span>
                </div>
                <div className="tag">
                    <span>Inaugurations</span>
                </div>
                <div className="tag">
                    <span>Concerts</span>
                </div>
            
            </div>
            <div className="line">
                <div className="tag">
                    <span>Réceptions</span>
                </div>
                <div className="tag">
                    <span>Comités d’entreprise</span>
                </div>
                <div className="tag">
                    <span>Vins d’honneur</span>
                </div>
            </div>
        </div>
        <div className="tagWrapper tagWrapperMobile">
            <div className="line">
                <div className="tag">
                    <span>Réceptions</span>
                </div>
                <div className="tag">
                    <span>Café-concert</span>
                </div>
            </div>
            <div className="line">
                <div className="tag">
                    <span>Restaurants</span>
                </div>
                <div className="tag">
                    <span>Bars d’ambiance</span>
                </div>
            </div>
            <div className="line">
                <div className="tag">
                    <span>Anniversaires</span>
                </div>
                <div className="tag">
                    <span>Mariages</span>
                </div>
            </div>
            <div className="line">
                <div className="tag">
                    <span>Inaugurations</span>
                </div>
                <div className="tag">
                    <span>Comités d’entreprise</span>
                </div>
            </div>
            <div className="line">
                <div className="tag">
                    <span>Concerts</span>
                </div>
                <div className="tag">
                    <span>Vins d’honneur</span>
                </div>
            </div>
        </div>
        <span>Nous mettrons tout en oeuvre afin de répondre au mieux à vos attentes et partager ensemble un moment convivial et festif.</span>
      </div>
    </div>
  );
}

export default Event;